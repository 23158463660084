
/*

  green - #5AAF94
  white - #FFFFF2
  purple - #791E94
  orange - #DE6449
  brown - #A38560

*/

.aws-btn {
  --button-default-height: 44px;
  --button-default-font-size: 16px;
  --button-default-border-radius: 13px;
  --button-horizontal-padding: 17px;
  --button-raise-level: 2px;
  --button-hover-pressure: 1;
  --transform-speed: 0.15s;
  --button-primary-color: #A38560;
  --button-primary-color-dark: #80684a;
  --button-primary-color-light: #FFFFF2;
  --button-primary-color-hover: #a39a60;
  --button-primary-color-active: #8a8358;
  --button-primary-border: none;
  --button-secondary-color: #f2f6f9;
  --button-secondary-color-dark: #1360a4;
  --button-secondary-color-light: #1e88e5;
  --button-secondary-color-hover: #e1eaf1;
  --button-secondary-color-active: #cfdee9;
  --button-secondary-border: 2px solid #1e88e5;
  --button-anchor-color: #0e4f88;
  --button-anchor-color-dark: #072743;
  --button-anchor-color-light: #ffffff;
  --button-anchor-color-hover: #0d4a7f;
  --button-anchor-color-active: #0c4271;
  --button-anchor-border: none;
  --button-danger-color: #c42e2e;
  --button-danger-color-dark: #861f1f;
  --button-danger-color-light: #ffffff;
  --button-danger-color-hover: #bc2c2c;
  --button-danger-color-active: #af2929;
  --button-danger-border: none;
}

.img-button {
  width: 25px;
  height: 25px;
}

.white-svg, .white-svg svg, .white-svg img {
  fill: #FFFFF2 !important;
  color: #FFFFF2 !important;
}

.img-button:hover {
  cursor: pointer;
  color: #FFFFF2;
}

.stack-count {
  color: #FFFFF2;
  background-color: #A38560;
  border-radius: 360px;
  width: 20px;
  top: -5px;
  position: relative;
}

.signin button {
  align-items: center;
  background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  height: 40px;
  width: 150px;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.signin button:active,
.signin button:hover {
  outline: 0;
}

.signin button span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.signin button:hover span {
  background: none;
}
/* 
button {
  background-color: #A38560;
  height: 44px;
  font-size: 24px;
  text-align: center;
  width: 190px;
  border-radius: 13px;
  border: 1px solid #8a8358;
  color: #FFFFF2;
  padding: 15px;
  line-height: 10px;
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

button:hover {
  cursor: pointer;
  background-color: #a39a60;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: #DE6449;
  color: #FFFFF2;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  font-size: 18px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
